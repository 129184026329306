<template>
  <van-swipe :autoplay="3000" lazy-render>
    <van-swipe-item v-for="image in banners" :key="image">
      <img class="banner-img" @click="toLink(image)" :src="image.url" />
    </van-swipe-item>
  </van-swipe>
  <div class="company-title">入驻艺龙酒店科技平台公司</div>
  <div class="hotel-company">酒管公司</div>
  <ul class="company-box">
    <li
      @click="toHotlePage(item)"
      v-for="(item, index) in companyList"
      :key="index"
    >
      <img :src="item.logoUrl" />
    </li>
  </ul>
  <div style="margintop: 30rem" class="hotel-company">赋能平台</div>
  <ul class="company-box">
    <li
      @click="toAssignPlatPage(item)"
      v-for="(item, index) in assignPlats.filter((item) => item.id !== 1)"
      :key="index"
    >
      <img :src="item.logoUrl" />
    </li>
  </ul>
  <div style="margintop: 30rem" class="hotel-company">会员体系</div>
  <ul class="company-box">
    <li
      v-for="(item, index) in assignPlats.filter((item) => item.id === 1)"
      :key="index"
      @click="toAssignPlatPage(item)"
      class="hide-line"
    >
      <img :src="item.logoUrl" />
    </li>
  </ul>
  <img
    class="company-advantage-img"
    :src="require('@/assets/img/公司优势.jpg')"
  />
  <div v-if="newList && newList.length" class="brand-news">
    <div class="en">NEWS CENTER</div>
    <div class="ch">品牌资讯</div>
  </div>
  <div v-if="newList && newList.length" class="news-wrap">
    <brandNews :newList="newList" />
  </div>
  <appFooter />
</template>

<script setup>
import { ref } from "vue";
import { getIndexPageData } from "@/api/index";
import { onMounted } from "@vue/runtime-core";
import brandNews from "@/components/brandNew.vue";
import { useRouter } from "vue-router";
import appFooter from "@/components/footer.vue";
import { trackEvent } from "@/utils/tracklog.js";

const banners = ref([]);
const newList = ref([]);
const companyList = ref([]);
const assignPlats = ref([]);
const router = useRouter();

onMounted(() => {
  getIndexPageData().then((data) => {
    const { indexVos, companyVos, enableCompanyConfigVos, infoContentVos } =
      data;
    banners.value = indexVos;
    newList.value = infoContentVos;
    companyList.value = companyVos;
    assignPlats.value = enableCompanyConfigVos;
  });
  trackEvent("浏览页面", "浏览", "浏览页面-首页", {}, 0, new Date().getTime());
});

const toLink = (item) => {
  if (!item.link) {
    return;
  }
  const url = item.link.split("#")[1];
  const baseUrl = window.location.href.split("#")[0];
  window.open(`${baseUrl}#${url}`);
};

const toHotlePage = (item) => {
  // 埋点 酒管公司
  trackEvent(
    "首页-酒管公司",
    "点击",
    "首页-酒管公司",
    { path: `/hotel/${item.id}?companyId=${item.companyId}` },
    0,
    new Date().getTime()
  );
  router.push(`/hotel/${item.id}`);
};
const toAssignPlatPage = (item) => {
  // 埋点 业务中台
  trackEvent(
    `首页-${item.id !== 1 ? "业务中台" : "会员体系"}`,
    "点击",
    `首页-${item.id !== 1 ? "业务中台" : "会员体系"}`,
    {
      path: `${item.id !== 1 ? "/assignPlatform/" : "/elongUnit/"}${item.id}`,
    },
    0,
    new Date().getTime()
  );
  if (item.id !== 1) {
    router.push(`/assignPlatform/${item.id}`);
    return;
  }
  router.push(`/elongUnit/${item.id}`);
};
</script>

<style lang="scss" scoped>
.banner-img {
  width: 750rem;
}
.company-title {
  font-size: $font-size-large-x;
  text-align: center;
  margin: 40rem 0;
}
.hotel-company {
  color: #005352;
  text-align: center;
  margin-bottom: 24rem;
  font-size: 30rem;
}
.company-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  li {
    width: 187.5rem;
    display: flex;
    justify-content: center;
    margin-bottom: 20rem;
    position: relative;
    &:not(:nth-child(4n)) {
      &::after {
        content: "";
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 1rem;
        height: 80rem;
        background: $color-border;
      }
    }
    &.hide-line {
      &::after {
        background: none;
      }
    }
    img {
      width: 128rem;
      height: 128rem;
    }
  }
}
.company-advantage-img {
  width: 750rem;
}
.brand-news {
  position: relative;
  margin: 48rem auto 42rem;
  width: fit-content;
  .en {
    font-size: $font-size-large-x;
    color: #eee;
  }
  .ch {
    font-size: $font-size-large-x;
    position: absolute;
    top: 46%;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
  }
}
</style>