<template>
  <ul class="new-wrap">
    <li 
      v-for="(item, index) in newList"
      @click="lookNewsDetail(item)" 
      :key="index">
      <img :src="item.url" v-lazy="item.url">
      <div class="content-wrap">
        <p class="new-title">{{item.title}}</p>
        <!-- <p class="time">{{item.createTime}}</p> -->
        <p class="content">{{item.description}}</p>
        <p class="company" v-if="item.belongCompanyName">酒管公司:{{item.belongCompanyName}}</p>
        <p class="company" v-if="item.belongEnableCompanyName">赋能平台:{{item.belongEnableCompanyName}}</p>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'brandNew',
  props: {
    newList:{
      type: Array,
      default: ()=>([])
    }
  },
  methods: {
    lookNewsDetail({id}){
      this.$router.push({
        path: '/newsDetail',
        query: {
          id
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.new-wrap{
    padding: 30rem;
    width: 100%;
    box-sizing: border-box;

    li{
        display: flex;
        margin-bottom: 20rem;
        width: 100%;
        box-sizing: border-box;
        img{
            width: 210rem;
            height: 146rem;
        }
        .content-wrap{
            font-size: $font-size-medium-x;
            line-height: 28rem;
            padding:0 0 10rem 20rem;
            width: calc(100% - 210rem);
            box-sizing: border-box;
            position: relative;

            .new-title{
                overflow: hidden;
                text-overflow: ellipsis;
                line-clamp: 2;
                display: -webkit-box;
                text-overflow: -o-ellipsis-lastline;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                margin-bottom: 10rem;
            }
            .content{
                color: #999;
                overflow: hidden;
                text-overflow: ellipsis;
                line-clamp: 2;
                display: -webkit-box;
                text-overflow: -o-ellipsis-lastline;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
            }
            .company {
              color: #999;
              margin-top: 16rem;
              position: absolute;
              bottom: 0;
              left: 20rem;
            }
        }
    }
}
</style>